import { ChangeDetectionStrategy, Component, Input, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { getUser } from 'src/app/auth/store';
import { BackgroundComponent } from 'src/app/standalone/background/background.component';

@Component({
  selector: 'app-questionnaire-completed',
  standalone: true,
  imports: [IconModule, BackgroundComponent, LottieComponent, RouterModule, DictionaryNgrxModule],
  templateUrl: './questionnaire-completed.component.html',
  styleUrl: './questionnaire-completed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireCompletedComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/confetti.json',
    loop: false,
  };
  store = inject(Store);
  profile$ = this.store.selectSignal(getUser);
}
