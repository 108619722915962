<section class="questionnaire-completed container container-size-xs">
  <figure class="questionnaire-completed__check pt-0 pt-md-5">
    <span class="chip chip--primary chip--pulsing chip--success mt-6 mb-7 bg-transparent">
      <fstr-icon anchor="icon-circle-check"></fstr-icon>
    </span>
    <div class="questionnaire-completed__animation">
      <ng-lottie [options]="options"></ng-lottie>
    </div>
  </figure>

  <main class="card shadow-sm questionnaire-completed__message">
    <div class="card-body">
      <h1 class="text-primary mb-3">{{ 'einde-vragenlijst-bedankt-titel' | fromDictionary }}</h1>
      <p>
        Je krijgt een rapport van je resultaten toegestuurd naar je mail:
        <span class="text-primary">{{ profile$()?.email }}</span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam scelerisque mauris et tellus euismod, et tristique mi fermentum.
        Curabitur et ex pellentesque, ornare tellus quis, placerat quam.
      </p>
    </div>
  </main>
  <div class="text-center my-5">
    <button class="button button-outline button--primary" [routerLink]="['/vragenlijsten']">
      <fstr-icon anchor="icon-arrow-left"></fstr-icon>
      <span>{{ 'terug-naar-vragenlijst-overzicht-knoptekst' | fromDictionary }}</span>
    </button>
  </div>
</section>
